import './App.css';
import Navbar from './components/navbar';
import Nav from './components/nav.js';
import Result from './Pages/result.js';
import SelfTest3 from './Pages/selftest3.js';
import Login from './Pages/Login/Login.js'
import Register from './Pages/Register/Register.js'
import Home from './Pages/Home/Home.js'
import TakeSpin from './Pages/Home/TakeSpin.js'
import Profile from './Pages/Profile.js';
import Contact from './Pages/Contact.js';

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";


function AppContent() {
  const location = useLocation();

  // Determine if the Navbar should be shown
  const showNavbar = location.pathname !== '/register' && location.pathname !== '/takespin' && location.pathname !== '/login' && location.pathname !== '/';

  return (
    <>
      {showNavbar && <Nav />}
      <Routes>
        <Route path='/test' element={<SelfTest3 />} />
        <Route path='/result' element={<Result />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Login/>} />
        <Route path='/home' element={<Home />} />
        <Route path='/takespin' element={<TakeSpin />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <div>
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

export default App;

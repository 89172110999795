import React, { useState, useEffect, useContext } from "react";
import temp from '../../images/dummy_model.png'
import temp_f from '../../images/dummy_model_female.png'
import test from '../../images/sample_test.png'
import scan from '../../images/face_scan_mob.png'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { UserContext } from "../../context/UserContext"; 

const Home = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { user } = useContext(UserContext);
    const [model, setModel] = useState('Female');

    useEffect(() => {
        if (user?.gender) {
        setModel(user.gender);
        }
    }, [user]);

    const redirectTest = () => {
        navigate('/test')   
    }

    return (
        <div className="flex justify-center items-start min-h-screen bg-white">
            {/* D E S K T O P  ------> */}
            <div className="flex xxs:hidden lg:flex flex-col items-center h-full w-full mx-5 xl:mb-5 pb-10 overflow-hidden bg-cbg1">
                <div className="h-[30rem] w-full px-10 my-5 flex flex-row justify-between items-center bg-cbg1">
                    <div className="h-[30rem] w-[49%] py-2 text-justify">
                        <div className="w-full text-3xl lg:text-4xl xl:text-5xl text-tbg1">AIVOT.AI</div>
                        <div className="w-full text-base lg:text-lg xl:text-xl leading-5 lg:leading-[1.45rem]">
                            <br/>
                            Welcome to AIVOT.AI, where your perfect fit is just a click away. Our cutting-edge AI technology ensures that your shopping experience is tailored to your unique measurements. Say goodbye to ill-fitting clothes and hello to a wardrobe that fits you perfectly. Whether you're looking for sportswear, casual wear, or formal attire, AIVOT.AI has got you covered.
                            <br/><br/>
                            At AIVOT.AI, we believe that everyone deserves to wear clothes that fit perfectly. Our mission is to revolutionize the shopping experience by providing accurate body measurements using just a selfie. Our AI-driven platform is designed to make shopping easier, more enjoyable, and, most importantly, perfectly suited to your unique body shape. Join us in the future of fashion.
                        </div>
                    </div>
                    <div className="w-[49%] px-10 py-0 flex justify-center">
                        <img className="max-w-[100%]" src={test} />
                    </div>
                </div>
                <div className="w-full px-10 pb-20 flex justify-start bg-cbg1">
                    <button 
                        className="px-10 py-1 text-center rounded-xl shadow-custom-inout-3 text-white text-2xl tracking-[0.5rem] bg-tbg1 
                                    hover:scale-90 transition-all ease-in-out duration-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-3"
                        onClick={redirectTest}
                    >
                        Take Test
                    </button>
                </div>
                <div className="h-fit w-full px-10 mt-5 flex flex-row justify-between bg-custom-linear-9">
                    <div className="h-fit w-[50%] lg:px-20 flex items-start justify-center">
                        <img src={model==='Male' ? temp : temp_f} className="w-full"/>
                    </div>
                    <div className="w-[50%] py-2 text-justify">
                        <div className="w-full pb-5 text-3xl lg:text-4xl xl:text-5xl text-tbg1">Body Measurement</div>
                        <div className="w-full text-base lg:text-lg xl:text-xl leading-5 lg:leading-[1.45rem]">
                            <br/>
                            Your body, your measurements, your style. Use our AI-powered tool to capture your body measurements with just a selfie. Our technology analyzes your photo to provide precise measurements for your neck, shoulders, chest, waist, hips, and more. No more guessing sizes—just input your measurements and discover clothing that fits you perfectly.
                            <br/><br/>
                            Ready to find your perfect fit? Follow our simple steps to take a selfie in proper lighting. Our AI will analyze your photo and provide you with accurate body measurements in seconds. From there, explore clothing options tailored to your measurements, making your shopping experience seamless and stress-free.
                        </div>
                    </div>
                </div>
                <div className="h-[5rem] lg:h-[10rem] w-full bg-custom-linear-10"></div>
                <div className="w-full px-10 flex flex-row justify-between bg-cbg1">
                    <div className="w-[52%] pb-2 text-justify">
                        <div className="w-full text-start text-3xl lg:text-4xl xl:text-5xl text-tbg1">Guidelines for Accurate Measurements</div>
                        <ul className="w-full text-base pl-10 pt-10 lg:text-lg xl:text-xl leading-4 lg:leading-[1.5rem] list-disc list-outside">
                            <li className="mt-2"><b className="font-bold">Full face in Frame: </b>Ensure your entire body is visible in the photo. This helps the AI capture all relevant measurements accurately.</li>
                            <li className="mt-2"><b className="font-bold">Good Lighting: </b>Natural sunlight works best for clear, accurate photos. Avoid shadows or dim lighting that could affect the quality of the image.</li>
                            <li className="mt-2"><b className="font-bold">Look Straight at the Camera: </b>Keep your gaze fixed straight ahead at the camera to ensure that your facial and upper body measurements are accurate.</li>
                            <li className="mt-2"><b className="font-bold">No Blinking: </b>Try to keep your eyes open and avoid blinking while the photo is being taken. This helps the AI capture your facial features more precisely.</li>
                            <li className="mt-2"><b className="font-bold">Avoid Sunglasses, Goggles, and Hats: </b>Do not wear sunglasses, goggles, or hats. These can obscure your facial features and affect the accuracy of the measurements.</li>
                            <li className="mt-2"><b className="font-bold">Clear Face: </b>Make sure your face and forehead are not covered by hair. Pull your hair back if necessary, to ensure that your entire face is clearly visible.</li>
                        </ul>
                    </div>
                    <div className="w-[45%] flex justify-center items-center px-10 py-0">
                        <img className="" src={scan} />
                    </div>
                </div>
            </div>
            
            {/* M O B I L E  ------> */}
            <div className="flex lg:hidden flex-col items-center h-full w-[90rem] py-10 overflow-hidden bg-cbg1 text-center">
                <div className="w-full text-5xl text-tbg1">AIVOT.AI</div>
                <div className="w-[25rem] md:w-[35rem] px-10 py-5">
                    <img className="max-w-[100%]" src={test} />
                </div>
                <div className="w-full px-10 md:px-20 text-base text-justify leading-5">
                    <br/>
                    Welcome to AIVOT.AI, where your perfect fit is just a click away. Our cutting-edge AI technology ensures that your shopping experience is tailored to your unique measurements. Say goodbye to ill-fitting clothes and hello to a wardrobe that fits you perfectly. Whether you're looking for sportswear, casual wear, or formal attire, AIVOT.AI has got you covered.
                    <br/><br/>
                    At AIVOT.AI, we believe that everyone deserves to wear clothes that fit perfectly. Our mission is to revolutionize the shopping experience by providing accurate body measurements using just a selfie. Our AI-driven platform is designed to make shopping easier, more enjoyable, and, most importantly, perfectly suited to your unique body shape. Join us in the future of fashion.
                </div>
                <div className="w-full px-10 pt-14 pb-20 flex justify-center bg-cbg1">
                    <button 
                        className="px-5 py-1 text-center rounded-xl shadow-custom-inout-3 text-white text-2xl tracking-[0.5rem] bg-tbg1 
                                    hover:scale-95 transition-all ease-in-out duration-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-3"
                        onClick={redirectTest}
                    >
                        Take Test
                    </button>
                </div>
                <div className="w-full px-14 flex flex-col justify-center items-center bg-custom-linear-9">
                    <div className="w-full pb-5 text-5xl text-tbg1">Body Measurement</div>
                    <div className="w-full md:w-[80%] items-center justify-center">
                        <img src={model==='Male' ? temp : temp_f} className="max-w-[100%] w-full"/>
                    </div>
                </div>
                <div className="w-full px-10 md:px-20 py-10 text-justify text-base leading-5 bg-custom-linear-10">
                    <br/>
                    Your body, your measurements, your style. Use our AI-powered tool to capture your body measurements with just a selfie. Our technology analyzes your photo to provide precise measurements for your neck, shoulders, chest, waist, hips, and more. No more guessing sizes—just input your measurements and discover clothing that fits you perfectly.
                    <br/><br/>
                    Ready to find your perfect fit? Follow our simple steps to take a selfie in proper lighting. Our AI will analyze your photo and provide you with accurate body measurements in seconds. From there, explore clothing options tailored to your measurements, making your shopping experience seamless and stress-free.
                </div>
                <div className="w-full pt-10 md:px-20 text-5xl text-tbg1">Guidelines for Accurate Measurements</div>
                <div className="w-full flex justify-center items-center px-10 py-5">
                    <img className="w-[70%] md:w-[50%]" src={scan} />
                </div>
                <ul className="w-full pl-11 md:pl-20 pr-10 md:pr-20 pb-10 text-base text-justify leading-5 list-disc list-outside">
                    <li className="mt-2"><b className="font-bold">Full face in Frame: </b>Ensure your entire body is visible in the photo. This helps the AI capture all relevant measurements accurately.</li>
                    <li className="mt-2"><b className="font-bold">Good Lighting: </b>Natural sunlight works best for clear, accurate photos. Avoid shadows or dim lighting that could affect the quality of the image.</li>
                    <li className="mt-2"><b className="font-bold">Look Straight at the Camera: </b>Keep your gaze fixed straight ahead at the camera to ensure that your facial and upper body measurements are accurate.</li>
                    <li className="mt-2"><b className="font-bold">No Blinking: </b>Try to keep your eyes open and avoid blinking while the photo is being taken. This helps the AI capture your facial features more precisely.</li>
                    <li className="mt-2"><b className="font-bold">Avoid Sunglasses, Goggles, and Hats: </b>Do not wear sunglasses, goggles, or hats. These can obscure your facial features and affect the accuracy of the measurements.</li>
                    <li className="mt-2"><b className="font-bold">Clear Face: </b>Make sure your face and forehead are not covered by hair. Pull your hair back if necessary, to ensure that your entire face is clearly visible.</li>
                </ul>
            </div>
        </div>
    )
}

export default Home
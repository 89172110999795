import React from "react";
import logo from '../../images/logo_background.png'
 
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";

// Font.register({
//     family: 'Oswald',
//     src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
// });

const styles = StyleSheet.create({
    image: {
        position: "absolute",
        top: -410,
        left: -400,
        width: "240%",
        height: "185%",
        zIndex: -1,
        opacity: 0.35
    },

    container: {
        display: "flex",
        padding: "0vw 10vw",
        justifyContent: "center",
        alignItems: "center",
    },

    headingRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end",
        margin: "10vw 0 5vw 0",
    },

    headingText: {
        fontSize: "4.5vw",
        fontWeight: "bold",
    },

    infoSec: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
    },

    name: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        fontSize: "2.75vw",
        alignItems: "center",
        padding: "2vw 3vw",
        borderTop: "0.15vw solid black",
        borderLeft: "0.15vw solid black",
        borderRight: "0.15vw solid black",
    },

    details: {
        width: "25%",
        fontSize: "2.5vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2vw",
        borderLeft: "0.15vw solid black",
        borderTop: "0.15vw solid black",
    },

    details2: {
        width: "25%",
        display: "flex",
        fontSize: "2.5vw",
        justifyContent: "center",
        alignItems: "center",
        padding: "2vw",
        borderLeft: "0.15vw solid black",
        borderTop: "0.15vw solid black",
        borderBottom: "0.15vw solid black",
    },

    vitalRows: {
        display: "flex",
        flexDirection: "column",
        padding: "10vw 0",
        width: "100%",
        // border: "0.15vw solid black",
    },

    vitalsCol: {
        display: "flex",
        flexDirection: "row",
        padding: "0",
        width: "100%",
        borderBottom: "0.15vw solid black",
        borderLeft: "0.15vw solid black",
        // bordeRight: "0.15vw solid black",
    },

    measurement: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "6vw",
        width: "50%",
        fontSize: "2.2vw",
        //   fontWeight: "bold",
        // borderTop: "0.15vw solid black",
        //   borderLeft: "0.15vw solid black"
    },

    value: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "6vw",
        width: "25%",
        fontSize: "2.2vw",
        color: "#303030",
        // borderTop: "0.15vw solid black",
        borderLeft: "0.15vw solid black"
    },

    units: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "6vw",
        width: "25%",
        fontSize: "2.2vw",
        color: "#303030",
        // borderTop: "0.15vw solid black",
        borderLeft: "0.15vw solid black",
        borderRight: "0.15vw solid black"
    },

    vitalHeader: {
        fontSize: "2.5vw",
        color: "black",
        fontWeight: "bold",
    },

    noteSec: {
        display: "flex",
        justifyContent: "center",
        fontSize: "1.8vw",
        marginTop: "5vw",
    },
});

const MyPDF = ({dataset, name, age, gender, height, weight}) => {
  //  console.log(`neck:  ${dataset.neck}`)
    const results = [
        { measurement: "Neck", value: parseFloat(dataset.Neck).toFixed(2), unit: "cm" },
        { measurement: "Shoulder", value: parseFloat(dataset.Shoulder).toFixed(2), unit: "cm" },
        { measurement: "Arms", value: parseFloat(dataset.Arm).toFixed(2), unit: "cm" },
        { measurement: "Chest", value: parseFloat(dataset.Chest).toFixed(2), unit: "cm" },
        { measurement: "Waist", value: parseFloat(dataset.Waist).toFixed(2), unit: "cm" },
        { measurement: "Hip", value: parseFloat(dataset.Hip).toFixed(2), unit: "cm" },
        { measurement: "Thigh", value: parseFloat(dataset.Thigh).toFixed(2), unit: "cm" },
        { measurement: "Knee", value: parseFloat(dataset.Knee).toFixed(2), unit: "cm" },
        { measurement: "Ankle", value: parseFloat(dataset.Ankle).toFixed(2), unit: "cm" },
        { measurement: "Inseam", value: parseFloat(dataset.Inseam).toFixed(2), unit: "cm" },
        { measurement: "Outseam", value: parseFloat(dataset.Outseam).toFixed(2), unit: "cm" },
        { measurement: "Foot Length", value: parseFloat(((dataset.Foot)*0.667)-1).toFixed(2), unit: "cm" },
    ];
    
   

    return (
        <Document>
            <Page size={"A4"}>
                <View style={styles.container}>
                    <View style={styles.headingRow}>
                        <Text style={styles.headingText}>Your Measurements</Text>
                    </View>
                    <View style={styles.infoSec}>
                        <View style={styles.name}>
                            <Text>Name:  </Text>
                            <Text>{name}</Text>
                        </View>
                    </View>
                    <View style={styles.infoSec}>
                        <View style={styles.details}>
                            <Text>Gender</Text>
                        </View>
                        <View style={styles.details}>
                            <Text>Age</Text>
                        </View>
                        <View style={styles.details}>
                            <Text>Height</Text>
                        </View>
                        <View
                            style={[
                                styles.details,
                                {
                                borderRight: "0.15vw solid black",
                                }
                            ]}
                        >
                            <Text>Weight</Text>
                        </View>
                    </View>
                    <View style={styles.infoSec}>
                        <View style={styles.details2}>
                            <Text>{gender}</Text>
                        </View>
                        <View style={styles.details2}>
                            <Text>{age}</Text>
                        </View>
                        <View style={styles.details2}>
                        <Text>{parseFloat(height).toFixed(2)} m</Text>
                        </View>
                        <View
                            style={[
                                styles.details2,
                                {
                                borderRight: "0.15vw solid black",
                                }
                            ]}
                        >
                            <Text>{parseFloat(weight).toFixed(2)} Kg</Text>
                            </View>
                    </View>
                    <View style={styles.vitalRows}>
                        <View style={[
                            styles.vitalsCol,
                            {
                            borderTop: "0.15vw solid black",
                            }
                        ]}>
                        <View style={styles.measurement}>
                            <Text style={styles.vitalHeader}>Measurement</Text>
                        </View>
                        <View style={styles.value}>
                            <Text style={styles.vitalHeader}>Value</Text>
                        </View>
                        <View style={styles.units}>
                            <Text style={styles.vitalHeader}>Unit</Text>
                        </View>
                        </View>
                        <View style={styles.vitalCol}>
                        {results.map((param, index) => (
                            <View style={styles.vitalsCol} key={index}>
                                <View style={styles.measurement}>
                                    <Text>{param.measurement}</Text>
                                </View>
                                <View style={styles.value}>
                                    <Text>{param.value}</Text>
                                </View>
                                <View style={styles.units}>
                                    <Text>{param.unit}</Text>
                                </View>
                            </View>
                        ))}
                        </View>
                        {/* <View style={styles.noteSec}>
                        <Text>
                            Note: These values are only indicative, cannot be used for clinical
                        </Text>
                        </View> */}
                    </View>
                </View>
                <Image
                    src={logo}
                    style={styles.image}
                />
            </Page>
        </Document>
    )
}

export default MyPDF;
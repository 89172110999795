import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from "./result.module.css";
import maleModel from "../images/male_model.png";
import femaleModel from "../images/Female.png";
import male_mob from "../images/dummy_model.png";
import female_mob from "../images/female_mob.png";
import MyPdf from "./ReactPDF/MyPdf.js";
import { PDFDownloadLink } from "@react-pdf/renderer";

const Result = () => {
  const location = useLocation();
  const { name, age, gender, height, weight, result } = location.state || {};
  const bodyModel = gender === "Male" ? maleModel : femaleModel;
  const bodymob = gender === "Male" ? male_mob : female_mob;

  const [unit, setUnit] = useState("cm"); // State to manage the current unit

  // Function to convert cm to inches
  const convertToInch = (cmValue) => {
    return (cmValue / 2.54).toFixed(2); // Convert and round to 2 decimal places
  };

  // Function to format measurement based on the selected unit
  const formatMeasurement = (value) => {
    if (value === undefined || value === null) return "-";
    if (unit === "inch") {
      return convertToInch(value);
    }
    return value.toFixed(2);
  };

  // Toggle unit between cm and inch
  const toggleUnit = () => {
    setUnit((prevUnit) => (prevUnit === "cm" ? "inch" : "cm"));
  };

  // M E A S U R E M E N T   M A P P I N G ------------------------------------------------------------------------------------------------------------------------------------------>
  const convertToInches = (cm) => cm / 2.54;

  // Pants Sizes
  let waist = convertToInches(result?.Waist) - 1;
  console.log("Waist-------------->", waist);
  const getPantSize = (brand, sizeRanges) => {
    const ranges = sizeRanges[brand];
    if (!ranges) return 'Size not available'; // Handle case where the brand is not in sizeRanges

    for (const { range, size } of ranges) {
      const [min, max] = range;
      if (waist >= min && waist < max) return size;
    }
    return 'Size not available';
  };
  const malePantSizes = {
    "Levis": [{ range: [-Infinity, 30], size: 'Size not available'}, { range: [28, 30], size: 28 }, { range: [30, 32], size: 30 }, { range: [32, 34], size: 32 }, { range: [34, 36], size: 34 }, { range: [36, 38], size: 36 }, { range: [38, 40], size: 38 }, { range: [40, 42], size: 40 }, { range: [42, 44], size: 42 }, { range: [44, Infinity], size: 'Size not available'}],
    "Pepe Jeans": [{ range: [-Infinity, 31], size: 'Size not available'}, { range: [31, 33], size: 30 }, { range: [33, 35], size: 32 }, { range: [35, 37], size: 34 }, { range: [37, 39], size: 36 }, { range: [39, 41], size: 38 }, { range: [41, Infinity], size: 'Size not available'}],
    "Wrangler": [{ range: [-Infinity, 27], size: 'Size not available'}, { range: [27, 29], size: 28 }, { range: [29, 31], size: 30 }, { range: [31, 33], size: 32 }, { range: [33, 35], size: 34 }, { range: [35, 37], size: 36 }, { range: [37, 39], size: 38 }, { range: [39, 41], size: 40 }, { range: [41, 43], size: 42 }, { range: [43, Infinity], size: 'Size not available'}],
    "Lee": [{ range: [-Infinity, 29], size: 'Size not available'}, { range: [29, 31], size: 28 }, { range: [31, 33], size: 30 }, { range: [33, 35], size: 32 }, { range: [35, 37], size: 34 }, { range: [37, 39], size: 36 }, { range: [39, 41], size: 38 }, { range: [41, 43], size: 40 }, { range: [43, 45], size: 42 }, {range: [45, Infinity], size: 'Size not available'}],
    "U.S.POLO": [{ range: [-Infinity, 29.5], size: 'Size not available'}, { range: [29.5, 31.5], size: 28 }, { range: [31.5, 33.5], size: 30 }, { range: [33.5, 35.5], size: 32 }, { range: [35.5, 37.5], size: 34 }, { range: [37.5, 39.5], size: 36 }, { range: [39.5, 41.5], size: 38 }, { range: [41.5, 43.5], size: 40 }, { range: [43.5, 45.5], size: 42 }, { range: [45.5, Infinity], size: 'Size not available'}]
  };
  const femalePantSizes = {
    "Levis": [{ range: [-Infinity, 25.6], size:'Size not available'  }, { range: [25.6, 27.6], size: 25 }, { range: [27.6, 28.6], size: 26 }, { range: [28.6, 29.6], size: 27 }, { range: [29.6, 30.6], size: 28 }, { range: [30.6, 32.6], size: 30 }, { range: [32.6, 35.6], size: 32 }, { range: [35.6, 39.6], size: 34 }, { range: [39.6, Infinity], size: 'Size not available'}],
    "Pepe Jeans": [{ range: [-Infinity, 24], size:'Size not available'  },{ range: [24, 26], size: 'XS' }, { range: [26, 28], size: 'S' }, { range: [28, 30], size: 'M' }, { range: [30, 32], size: 'L' }, { range: [32, 34], size: 'XL' },{ range: [34, Infinity], size: 'Size not available'}],
    "Wrangler": [{ range: [-Infinity, 24], size:'Size not available'  },{ range: [24, 26], size: 26 }, { range: [26, 28], size: 28 }, { range: [28, 30], size: 30 }, { range: [30, 32], size: 32 }, { range: [32, 34], size: 34 },{ range: [34, Infinity], size: 'Size not available'}],
    "Lee": [{ range: [-Infinity, 26], size:'Size not available'  },{ range: [26, 28], size: 28 }, { range: [28, 30], size: 30 }, { range: [30, 32], size: 32 }, { range: [32, 34], size: 34 }, { range: [34, 36], size: 36 },{ range: [36, Infinity], size: 'Size not available'}],
    "U.S.POLO": [{ range: [-Infinity, 25], size:'Size not available'  },{ range: [25, 27.5], size: 26 }, { range: [27.5, 29.5], size: 28 }, { range: [29.5, 31.5], size: 30 }, { range: [31.5, 33.5], size: 32 }, { range: [33.5, 35.5], size: 34 }, { range: [35.5, 37.5], size: 36 },{ range: [37.5, Infinity], size: 'Size not available'}]
  };
 
  const pantSizes = gender === 'Male' ? malePantSizes : femalePantSizes;

  console.log(`U.S.POLO: ${getPantSize('U.S.POLO', pantSizes)}, Levis: ${getPantSize('Levis', pantSizes)}, Pepe Jeans: ${getPantSize('Pepe Jeans', pantSizes)}, Wrangler: ${getPantSize('Wrangler', pantSizes)}, Lee: ${getPantSize('Lee', pantSizes)}`);

  // Shirt Sizes
  let chest = convertToInches(result?.Chest);
  console.log("Chest-------------->", chest);
  console.log(typeof(chest))


  const getShirtSize = (brand, sizeRanges) => {
    const ranges = sizeRanges[brand];
    if (!ranges) return 'Size not available'; // Handle case where the brand is not in sizeRanges

    for (const { range, size } of ranges) {
      const [min, max] = range;
      if (chest >= min && chest < max) return size;
    }
    return 'Size not available';
  };
  const maleShirtSizes = {
    "U.S.POLO": [{ range: [-Infinity, 37.5], size: 'Size not available'}, { range: [37.5, 39.5], size: 'S' }, { range: [39.5, 41.5], size: 'M' }, { range: [41.5, 45], size: 'L' }, { range: [45, 47], size: 'XL' }, { range: [47, 50.5], size: 'XXL' }, { range: [50.5, 52.5], size: '3XL' }, { range: [52.5, Infinity], size: 'Size not available'}],
    "Allen Solly": [{ range: [-Infinity, 38.75], size: 'Size not available'}, { range: [38.75, 40.25], size: 38 }, { range: [40.25, 41.75], size: 39 }, { range: [41.75, 43.5], size: 40 }, { range: [43.5, 46], size: 42 }, { range: [46, 48.5], size: 44 }, { range: [48.5, 51], size: 46 }, { range: [51, 53.5], size: 48 }, { range: [53.5, 56], size: 50 }, { range: [56, Infinity], size: 'Size not available'}],
    "Van Heusen": [{ range: [-Infinity, 37.75], size: 'Size not available'}, { range: [37.75, 39.5], size: 36 }, { range: [39.5, 40.25], size: 38 }, { range: [40.25, 42], size: 39 }, { range: [42, 43.5], size: 40 }, { range: [43.5, 46], size: 42 }, { range: [46, 48.5], size: 44 }, { range: [48.5, 51], size: 46 }, { range: [51, 53.5], size: 48 }, { range: [53.5, 56], size: 50 }, { range: [56, Infinity], size: 'Size not available'}],
    "Louis Philippe": [{ range: [-Infinity, 30.31], size: 'Size not available'}, { range: [30.31, 32.28], size: 36 }, { range: [32.28, 34.25], size: 38 }, { range: [34.25, 36.22], size: 39 }, { range: [36.22, 38.18], size: 40 }, { range: [38.18, 40.15], size: 42 }, { range: [40.15, 42.12], size: 44 }, { range: [42.12, 44.09], size: 46 }, { range: [44.09, 46.06], size: 48 }, { range: [46.06, 47.63], size: 50 }, { range: [47.63, Infinity], size: 'Size not available'}],
    "Flying Machine": [{ range: [-Infinity, 36], size: 'Size not available'}, { range: [36, 38], size: 'S' }, { range: [38, 40], size: 'M' }, { range: [40, 42], size: 'L' }, { range: [42, 44], size: 'XL' }, { range: [44, 46], size: 'XXL' }, { range: [46, 48], size: '3XL' }, { range: [48, Infinity], size: 'Size not available'}]
  };
  const femaleShirtSizes = {
    "U.S.POLO": [{ range: [-Infinity, 37.25], size:'Size not available'  },{ range: [37.25, 39.5], size: 'S' },{ range: [39.5, 41.75], size: 'M' },{ range: [41.75, 45], size: 'L' },{ range: [45, 47.25], size: 'XL' },{ range: [47.25, 50.5], size: 'XXL' },{ range: [50.5, 53.5], size: 'XXXL' }, { range: [53.5, Infinity], size: 'Size not available'}],
    "Allen Solly": [{ range: [-Infinity, 32], size:'Size not available'  },{ range: [32, 34.5], size: 'XS' },{ range: [34.5, 36], size: 'S' },{ range: [36, 38], size: 'M' },{ range: [38, 40], size: 'L' },{ range: [40, 42.5], size: 'XL' },{ range: [42.5, 45], size: 'XXL' },{ range: [45, 48], size: 'XXXL' }, { range: [48, Infinity], size: 'Size not available'}],
    "Van Heusen": [ { range: [-Infinity, 31], size:'Size not available'  },{ range: [31, 34], size: 'XS' }, { range: [34, 35.5], size: 'S' }, { range: [35.5, 37.5], size: 'M' }, { range: [37.5, 39.5], size: 'L' }, { range: [39.5, 41.88], size: 'XL' }, { range: [41.88, 44.25], size: 'XXL' }, { range: [44.25, 47.25], size: 'XXXL' }, { range: [47.25, Infinity], size: 'Size not available'}],
    "Louis Philippe": [{ range: [-Infinity, 33], size:'Size not available'  },{ range: [33, 36], size: 'S' },{ range: [36, 38], size: 'M' },{ range: [38, 40], size: 'L' },{ range: [40, 42], size: 'XL' },{ range: [42, 44], size: 'XXL' }, { range: [44, Infinity], size: 'Size not available'}],
    "Flying Machine": [{ range: [-Infinity, 29], size:'Size not available'  }, { range: [29, 31.5], size: 'XS' }, { range: [31.5, 33.5], size: 'S' }, { range: [33.5, 37.5], size: 'M' }, { range: [37.5, 39.5], size: 'L' }, { range: [39.5, 41.5], size: 'XL' }, { range: [41.5, 43.5], size: 'XXL' }, { range: [43.5, Infinity], size: 'Size not available'}]
  };
  const shirtSizes = gender === 'Male' ? maleShirtSizes : femaleShirtSizes;
  console.log(`U.S.POLO: ${getShirtSize('U.S.POLO', shirtSizes)}, Allen Solly: ${getShirtSize('Allen Solly', shirtSizes)}, Van Heusen: ${getShirtSize('Van Heusen', shirtSizes)}, Louis Philippe: ${getShirtSize('Louis Philippe', shirtSizes)}, Flying Machine: ${getShirtSize('Flying Machine', shirtSizes)}`);

  // T-Shirt Sizes
  let shoulder = convertToInches(result?.Shoulder);
  const getTShirtSize = (brand, sizeRanges) => {
    if (brand === 'Louis Philippe') {
      const ranges = sizeRanges[brand];
      if (!ranges) return 'Size not available'; // Handle case where the brand is not in sizeRanges

      for (const { range, size } of ranges) {
        const [min, max] = range;
        if (shoulder >= min && shoulder < max) return size;
      }
      return 'Size not available';
    } else {
      const ranges = sizeRanges[brand];
      if (!ranges) return 'Size not available'; // Handle case where the brand is not in sizeRanges

      for (const { range, size } of ranges) {
        const [min, max] = range;
        if (chest >= min && chest < max) return size;
      }
      return 'Size not available';
    }
  };
  const maleTshirtSizes = {
    "U.S.POLO ASSN": [{ range: [-Infinity, 33], size: 'Size not available'}, { range: [33, 35], size: 'XS' }, { range: [35, 37], size: 'S' }, { range: [37, 39], size: 'M' }, { range: [39, 41], size: 'L' }, { range: [41, 43], size: 'XL' }, { range: [43, 45], size: 'XXL' }, { range: [45, 47], size: '3XL' }, { range: [47, 49], size: '4XL' }, { range: [49, Infinity], size: 'Size not available'}],
    "Allen Solly": [{ range: [-Infinity, 37], size: 'Size not available'}, { range: [37, 39], size: 'S' }, { range: [39, 41], size: 'M' }, { range: [41, 43], size: 'L' }, { range: [43, 46], size: 'XL' }, { range: [46, 49], size: 'XXL' }, { range: [49, 51], size: '3XL' }, { range: [51, Infinity], size: 'Size not available'}],
    // "Van Heusen": [{ range: [20, 32], size: 'S' }, { range: [32, 34], size: 'M' }, { range: [34, 38], size: 'L' }, { range: [38, 42], size: 'XL' }, { range: [42, 46], size: 'XXL' }, { range: [46, Infinity], size: '3XL' }],
    "Van Heusen": [{ range: [-Infinity, 33], size: 'Size not available'}, { range: [33, 35], size: 'S' }, { range: [35, 39], size: 'M' }, { range: [39, 43], size: 'L' }, { range: [43, 47], size: 'XL' }, { range: [47, 50], size: 'XXL' }, { range: [50, 52], size: '3XL' }, { range: [52, Infinity], size: 'Size not available'}],
    // "Louis Philippe": [{ range: [20, 36], size: 'S' }, { range: [36, 38], size: 'M' }, { range: [38, 40], size: 'L' }, { range: [40, 42], size: 'XL' }, { range: [42, 44], size: 'XXL' }, { range: [44, 46], size: 'XXL' }, { range: [44, Infinity], size: '3XL' }],
    "Louis Philippe":[{ range: [-Infinity, 14.96], size: 'Size not available'}, { range: [14.96, 15.75], size: 'XS' },   { range: [15.75, 16.54], size: 'S' },   { range: [16.54, 17.32], size: 'M' },   { range: [17.32, 18.11], size: 'L' },   { range: [18.11, 18.9], size: 'XL' },   { range: [18.9, 19.69], size: 'XXL' }, { range: [19.69, 20.47], size: '3XL' }, { range: [20.47, Infinity], size: 'Size not available'}],
    "Flying Machine": [{ range: [-Infinity, 32], size: 'Size not available'}, { range: [32, 34], size: 'XS' }, { range: [34, 36], size: 'S' }, { range: [36, 38], size: 'M' }, { range: [38, 40], size: 'L' }, { range: [40, 42], size: 'XL' }, { range: [42, 44], size: 'XXL' }, { range: [44, 46], size: '3XL' }, { range: [46, Infinity], size: 'Size not available'}],
    "Being Human": [{ range: [-Infinity, 35.04], size: 'Size not available'}, { range: [35.04, 37.01], size: 'S' }, { range: [37.01, 38.98], size: 'M' }, { range: [38.98, 40.94], size: 'L' }, { range: [40.94, 42.91], size: 'XL' }, { range: [42.91, 44.88], size: 'XXL' }, { range: [44.88, 46.85], size: '3XL' }, { range: [46.85, Infinity], size: 'Size not available'}],
  };
  const femaleTshirtSizes = {
    "U.S.POLO ASSN": [{ range: [-Infinity, 25], size:'Size not available'  },{ range: [25, 28], size: 'XXS' },{ range: [28, 30], size: 'XS' },{ range: [30, 32], size: 'S' },{ range: [32, 34], size: 'M' },{ range: [34, 36], size: 'L' },{ range: [36, 38], size: 'XL' },{ range: [38, 40], size: 'XXL' },{ range: [40, 42], size: '3XL' },{ range: [42, 44], size: '4XL' },{ range: [44, 46], size: '5XL' },{ range: [46, 48], size: '6XL' },{ range: [48, 50], size: '7XL' },{ range: [50, 52], size: '8XL' },{ range: [52, 54], size: 'Free' }, { range: [54, Infinity], size: 'Size not available'}],
    "Allen Solly": [{ range: [-Infinity, 32], size:'Size not available'  },{ range: [32, 34.5], size: 'XS' },{ range: [34.5, 36], size: 'S' },{ range: [36, 38], size: 'M' },{ range: [38, 40], size: 'L' },{ range: [40, 42.5], size: 'XL' },{ range: [42.5, 45], size: 'XXL' },{ range: [45, 47.5], size: '3XL' }, { range: [47.5, Infinity], size: 'Size not available'}],
    "Van Heusen": [{ range: [-Infinity, 30], size:'Size not available'  },{ range: [30, 32], size: 'XS' },{ range: [32, 33.5], size: 'S' },{ range: [33.5, 35], size: 'M' },{ range: [35, 36.5], size: 'L' },{ range: [36.5, 38], size: 'XL' },{ range: [38, 39.5], size: 'XXL' },{ range: [39.5, 43], size: '3XL' }, { range: [43, Infinity], size: 'Size not available'}],
    "Flying Machine": [{ range: [-Infinity, 31], size:'Size not available'  },{ range: [31, 34], size: 'S' },{ range: [34, 36], size: 'M' },{ range: [36, 38], size: 'L' },{ range: [38, 40], size: 'XL' },{ range: [40, 42], size: 'XXL' },{ range: [42, 44], size: 'XXXL' }, { range: [44, Infinity], size: 'Size not available'}],
    "Being Human": [{ range: [-Infinity, 35], size:'Size not available'  }, { range: [35, 37.01], size: 'S' }, { range: [37.01, 38.98], size: 'M' }, { range: [38.98, 40.94], size: 'L' }, { range: [40.94, 42.91], size: 'XL' }, { range: [42.91, 44.88], size: 'XXL' }, { range: [44.88, 47], size: '3XL' }, { range: [47, Infinity], size: 'Size not available'}]
  };
  const tShirtSizes = gender === 'Male' ? maleTshirtSizes : femaleTshirtSizes;
  console.log(`U.S.POLO ASSN: ${getTShirtSize('U.S.POLO ASSN', tShirtSizes)}, Allen Solly: ${getTShirtSize('Allen Solly', tShirtSizes)}, Van Heusen: ${getTShirtSize('Van Heusen', tShirtSizes)}, Louis Philippe: ${getTShirtSize('Louis Philippe', tShirtSizes)}, Flying Machine: ${getTShirtSize('Flying Machine', tShirtSizes)}, Being Human: ${getTShirtSize('Being Human', tShirtSizes)}`);

  // Footwear
  let foot = result?.Foot;

  console.log("Foot-------------->", foot);
  console.log(typeof(foot))
  const maleFootSize = {
    "Nike": [{ range: [-Infinity, 34.5], size: 'Size not available'}, { range: [34.5, 36], size: '4' }, { range: [36, 37.5], size: '5' }, { range: [37.5, 38.5], size: '6' }, { range: [38.5, 40], size: '7' }, { range: [40, 41], size: '8' }, { range: [41, 42.5], size: '9' }, { range: [42.5, 44], size: '10' }, { range: [44, 45], size: '11' }, { range: [45, 46], size: '12' }, { range: [46, 47.5], size: '13' }, { range: [47.5, Infinity], size: 'Size not available'}],
    "Puma": [{ range: [-Infinity, 34.5], size: 'Size not available'}, { range: [34.5, 35.5], size: '3' }, { range: [35.5, 36], size: '3.5' }, { range: [36, 37], size: '4' }, { range: [37, 37.5], size: '4.5' }, { range: [37.5, 38], size: '5' }, { range: [38, 38.5], size: '5.5' }, { range: [38.5, 39], size: '6' }, { range: [39, 40], size: '6.5' }, { range: [40, 40.5], size: '7' }, { range: [40.5, 41], size: '7.5' }, { range: [41, 42], size: '8' }, { range: [42, 42.5], size: '8.5' }, { range: [42.5, 43], size: '9' }, { range: [43, 44], size: '9.5' }, { range: [44, 44.5], size: '10' }, { range: [44.5, 45], size: '10.5' }, { range: [45, 46], size: '11' }, { range: [46, 46.5], size: '11.5' }, { range: [46.5, 47], size: '12' }, { range: [47, 48], size: '13' }, { range: [48, 49], size: '14' } ,{ range: [49, Infinity], size: 'Size not available'}],
    "Adidas": [{ range: [-Infinity, 32], size: 'Size not available'}, { size: '3', range: [32, 35.33] }, { size: '3.5', range: [35.33, 36] }, { size: '4', range: [36, 36.67] }, { size: '4.5', range: [36.67, 37.33] }, { size: '5', range: [37.33, 38] }, { size: '5.5', range: [38, 38.67] }, { size: '6', range: [38.67, 39.33] }, { size: '6.5', range: [39.33, 40] }, { size: '7', range: [40, 40.67] }, { size: '7.5', range: [40.67, 41.33] }, { size: '8', range: [41.33, 42] }, { size: '8.5', range: [42, 42.67] }, { size: '9', range: [42.67, 43.33] }, { size: '9.5', range: [43.33, 44] }, { size: '10', range: [44, 44.67] }, { size: '10.5', range: [44.67, 45.33] }, { size: '11', range: [45.33, 46] }, { size: '11.5', range: [46, 46.67] }, { size: '12', range: [46.67, 47.33] }, { size: '12.5', range: [47.33, 48] }, { size: '13', range: [48, 48.67] }, { size: '13.5', range: [48.67, 49.33] }, { size: '14', range: [49.33, 50] }, { size: '14.5', range: [50, 50.67] }, { size: '15', range: [50.67, 51.33] }, { size: '15.5', range: [51.33, 52] }, { size: '16', range: [52, 52.67] }, { size: '17', range: [52.67, 53.33] }, { size: '18', range: [53.33, 54.67] }, { size: '19', range: [54.67, 56.5] },{ range: [56.5, Infinity], size: 'Size not available'}],
    "Sketchers": [{ range: [-Infinity, 38.5], size: 'Size not available'}, { size: '7', range: [38.5, 39.5] }, { size: '8', range: [39.5, 41] }, { size: '9', range: [41, 42] }, { size: '10', range: [42, 43] }, { size: '11', range: [43, 45] }, { size: '12', range: [45, 46] }, { size: '13', range: [46, 47.5] },{ range: [47.5, Infinity], size: 'Size not available'}],
    "Reebok": [{ range: [-Infinity, 37.5], size: 'Size not available'}, { size: '6', range: [37.5, 39] }, { size: '7', range: [39, 40.5] }, { size: '8', range: [40.5, 42] }, { size: '9', range: [42, 43] }, { size: '10', range: [43, 44.5] }, { size: '11', range: [44.5, 45.5] }, { size: '12', range: [45.5, 47] }, { size: '13', range: [47, 48.5] },{ range: [48.5, Infinity], size: 'Size not available'}],
  };
  const femaleFootSize = {
    "Nike": [{ range: [-Infinity, 34], size: 'Size not available'}, { size: '5', range: [34, 35.5] }, { size: '5.5', range: [35.5, 36] }, { size: '6', range: [36, 36.5] }, { size: '6.5', range: [36.5, 37.5] }, { size: '7', range: [37.5, 38] }, { size: '7.5', range: [38, 38.5] }, { size: '8', range: [38.5, 39] }, { size: '8.5', range: [39, 40] }, { size: '9', range: [40, 40.5] }, { size: '9.5', range: [40.5, 41] }, { size: '10', range: [41, 42] }, { size: '10.5', range: [42, 42.5] }, { size: '11', range: [42.5, 43] }, { size: '11.5', range: [43, 44.5] }, { size: '12', range: [44.5, 45] }, { size: '13', range: [45, 45.5] },{ range: [45.5, Infinity], size: 'Size not available'}],
    "Puma": [{ range: [-Infinity, 34.5], size: 'Size not available'}, { range: [34.5, 35.5], size: '3' }, { range: [35.5, 36], size: '3.5' }, { range: [36, 37], size: '4' }, { range: [37, 37.5], size: '4.5' }, { range: [37.5, 38], size: '5' }, { range: [38, 38.5], size: '5.5' }, { range: [38.5, 39], size: '6' }, { range: [39, 40], size: '6.5' }, { range: [40, 40.5], size: '7' }, { range: [40.5, 41], size: '7.5' }, { range: [41, 42], size: '8' }, { range: [42, 42.5], size: '8.5' }, { range: [42.5, 43], size: '9' }, { range: [43, 44], size: '9.5' }, { range: [44, 44.5], size: '10' }, { range: [44.5, 45], size: '10.5' }, { range: [45, 46], size: '11' }, { range: [46, 46.5], size: '11.5' }, { range: [46.5, 47], size: '12' }, { range: [47, 48], size: '13' },{ range: [48, Infinity], size: 'Size not available'}],
    "Adidas": [{ range: [-Infinity, 32], size: 'Size not available'}, { size: '3', range: [32, 35.33] }, { size: '3.5', range: [35.33, 36] }, { size: '4', range: [36, 36.67] }, { size: '4.5', range: [36.67, 37.33] }, { size: '5', range: [37.33, 38] }, { size: '5.5', range: [38, 38.67] }, { size: '6', range: [38.67, 39.33] }, { size: '6.5', range: [39.33, 40] }, { size: '7', range: [40, 40.67] }, { size: '7.5', range: [40.67, 41.33] }, { size: '8', range: [41.33, 42] }, { size: '8.5', range: [42, 42.67] }, { size: '9', range: [42.67, 43.33] }, { size: '9.5', range: [43.33, 44] }, { size: '10', range: [44, 44.67] }, { size: '10.5', range: [44.67, 45.33] }, { size: '11', range: [45.33, 46] }, { size: '11.5', range: [46, 46.67] }, { size: '12', range: [46.67, 47.33] }, { size: '12.5', range: [47.33, 48] }, { size: '13', range: [48, 48.67] }, { size: '13.5', range: [48.67, 49.33] }, { size: '14', range: [49.33, 50] }, { size: '14.5', range: [50, 50.67] }, { size: '15', range: [50.67, 51.33] }, { size: '15.5', range: [51.33, 52] }, { size: '16', range: [52, 52.67] }, { size: '17', range: [52.67, 53.33] }, { size: '18', range: [53.33, 54.67] }, { size: '19', range: [54.67, 56.5] },{ range: [56.5, Infinity], size: 'Size not available'}],
    "Sketchers": [{ range: [-Infinity, 34], size: 'Size not available'}, { size: '5', range: [34, 35] }, { size: '6', range: [35, 36] }, { size: '7', range: [36, 37] }, { size: '8', range: [37, 38] }, { size: '9', range: [38, 39] }, { size: '10', range: [39, 40] }, { size: '11', range: [40, 41] },{ range: [41, Infinity], size: 'Size not available'}],
    "Reebok": [{ range: [-Infinity, 32], size: 'Size not available'}, { size: '1', range: [32, 33] }, { size: '1.5', range: [33, 34] }, { size: '2', range: [34, 34.5] }, { size: '2.5', range: [34.5, 35] }, { size: '3', range: [35, 35.5] }, { size: '3.5', range: [35.5, 36] }, { size: '4', range: [36, 37] }, { size: '4.5', range: [37, 37.5] }, { size: '5', range: [37.5, 38] }, { size: '5.5', range: [38, 38.5] }, { size: '6', range: [38.5, 39] }, { size: '6.5', range: [39, 40] }, { size: '7', range: [40, 40.5] }, { size: '7.5', range: [40.5, 41] }, { size: '8', range: [41, 42] },{ range: [42, Infinity], size: 'Size not available'}]
  };
  const footSize = gender === 'Male' ? maleFootSize : femaleFootSize;
  const getFootSize = (brand) => {
    if (age >= 18 && gender === 'Female') {
      console.log('Foot Modified----------------->');
      if ((brand === 'Nike' && foot < 38) || (brand === 'Sketchers' && foot < 37)) return 7;
      if ((brand === 'Puma' && foot < 37.5) || (brand === 'Adidas' && foot < 37.33) || (brand === 'Reebok' && foot < 37.5)) return 4.5;
    }
  
    const ranges = footSize[brand];
    console.log(brand);
    if (!ranges) return 'Size not available';
    
    for (const { range, size } of ranges) {
      const [min, max] = range.map(Number);
      if (foot >= min && foot < max) {
        const sizeNumber = Number(size);
        return isNaN(sizeNumber) ? 'Size not available' : sizeNumber;
      }
    }
  
    return 'Size not available';
  };
  console.log(`Nike--->${typeof(getFootSize('Nike'))}, Puma--->${getFootSize('Puma')}, Adidas--->${getFootSize('Adidas')}, Sketchers--->${getFootSize('Sketchers')}, Reebok--->${getFootSize('Reebok')}`)
  
  const malePantSizeCharts = {
    'U.S.POLO': [{ title: "Size", values: [28, 30, 32, 34, 36, 38, 40, 42] }, { title: "Waist", values: [29.5, 31.5, 33.5, 35.5, 37.5, 39.5, 41.5, 43.5] }, { title: "Inseam", values: Array(8).fill(33) }, { title: "Hip", values: [38, 40, 42, 44, 46, 48, 50, 52] },],
    'Levis': [{ title: "Size", values: [28, 30, 32, 34, 36, 38, 40, 42] }, { title: "Waist", values: [28, 30, 32, 34, 36, 38, 40, 42] }, { title: "Inseam", values: Array(8).fill(32) }, { title: "Hip", values: [39.3, 41.3, 43.3, 45.3, 47.3, 48.9, 50.5, 52.1] },],
    'Lee': [{ title: "Size", values: [28, 30, 32, 34, 36, 38, 40, 42] }, { title: "Waist", values: [29, 31, 33, 35, 37, 39, 41, 43] }, { title: "Inseam", values: Array(8).fill(33) }, { title: "Hip", values: [36.5, 38.5, 40.5, 42.5, 44.5, 46.5, 48.5, 50.5] },],
    'Pepe Jeans': [{ title: "Size", values: [30, 32, 34, 36, 38] }, { title: "Waist", values: [31, 33, 35, 37, 39] }, { title: "Inseam", values: Array(5).fill(34) }, { title: "Hip", values: [40, 42, 44, 46, 48] },],
    'Wrangler': [{ title: "Size", values: [28, 30, 32, 34, 36, 38, 40, 42] }, { title: "Waist", values: [28, 30, 32, 34, 36, 38, 40, 42] }, { title: "Inseam", values: Array(8).fill(33) }, { title: "Hip", values: [37, 39, 41, 43, 45, 47, 49, 51] },],
  };
  const femalePantSizeCharts = {
    'U.S.POLO': [{ title: "Size", values: [26, 28, 30, 32, 34, 36] }, { title: "Waist", values: [27.5, 29.5, 31.5, 33.5, 35.5, 37.5] }, { title: "Inseam", values: Array(6).fill(28) }],
    'Levis': [{ title: "Size", values: [25, 26, 27, 28, 30, 32, 34] }, { title: "Waist", values: [27.6, 28.6, 29.6, 30.6, 32.6, 35.6, 39.6] }, { title: "Inseam", values: Array(7).fill(30) }, { title: "Hip", values: [31.5, 32.5, 33.5, 34.5, 36.5, 39.5, 43] }],
    'Lee': [{ title: "Size", values: [28, 30, 32, 34, 36] }, { title: "Waist", values: [28, 30, 32, 34, 36] }, { title: "Inseam", values: Array(5).fill(31) }],
    'Pepe Jeans': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL'] }, { title: "Waist", values: [26, 28, 30, 32, 34, 36] }, { title: "Inseam", values: Array(6).fill(32) }],
    'Wrangler': [{ title: "Size", values: [26, 28, 30, 32, 34] }, { title: "Waist", values: [26, 28, 30, 32, 34] }, { title: "Inseam", values: Array(5).fill(31) }],
  };
  const pantSizeCharts = gender === 'Male' ? malePantSizeCharts : femalePantSizeCharts;

  const maleShirtSizeCharts = {
    'U.S.POLO': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'] }, { title: "Chest", values: [39.5, 41.5, 45, 47, 50.5, 52.5] }, { title: "Shoulder", values: [17, 18, 19, 20, 21, 22] }, { title: "Length", values: [28, 29, 30, 31, 31.5, 32.5] }],
    'Allen Solly': [{ title: "Size", values: [38, 39, 40, 42, 44, 46, 48, 50] }, { title: "Chest", values: [40.25, 41.75, 43.5, 46, 48.5, 51, 53.5, 56] }, { title: "Shoulder", values: [16.75, 17.5, 18.5, 19.5, 20.5, 21.5, 22.5, 23.5] }, { title: "Length", values: [30, 30.25, 30.5, 30.75, 31, 31.25, 31.5, 31.75] }],
    'Van Heusen': [{ title: "Size", values: [36, 38, 39, 40, 42, 44, 46, 48, 50] }, { title: "Chest", values: [39.5, 40.25, 42, 43.5, 46, 48.5, 51, 53.5, 56] }, { title: "Shoulder", values: [16.25, 16.75, 17.5, 18.5, 19.5, 20.5, 21.5, 22.25, 22.5] }, { title: "Length", values: [29.5, 30, 30.5, 31, 31.25, 31.5, 31.75, 32, 32.5] }],
    'Louis Philippe': [{ title: "Size", values: [36, 38, 39, 40, 42, 44, 46, 48, 50] }, { title: "Chest", values: [32.28, 34.25, 36.22, 38.18, 40.15, 42.12, 44.09, 46.06, 47.63] }, { title: "Shoulder", values: [14.56, 15.35, 16.14, 16.92, 17.71, 18.5, 19.29, 20.07, 19.68] }, { title: "Length", values: [24.4, 25.39, 26.37, 27.36, 28.34, 29.33, 30.31, 31.29, 31.29] }],
    'Flying Machine': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'] }, { title: "Chest", values: [38, 40, 42, 44, 46, 48] }, { title: "Shoulder", values: [17, 18, 19, 20, 21, 22] }, { title: "Length", values: [26, 27, 28, 29, 30, 31] }]
  };
  const femaleShirtSizeCharts = {
    'U.S.POLO': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'] }, { title: "Chest", values: [39.5, 41.75, 45, 47.25, 50.5, 52.75] }, { title: "Shoulder", values: [17, 18, 19, 20, 21.5, 22] }, { title: "Length", values: [28.25, 29.25, 29.75, 30.75, 31.5, 32] }, { title: "Waist", values: [37.5, 40, 44, 46.5, 50.5, 52.75] }],
    'Allen Solly': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'] }, { title: "Chest", values: [34.5, 36, 38, 40, 42.5, 45, 47] }, { title: "Shoulder", values: [14.38, 14.75, 15.25, 15.75, 16.5, 17.25, 17.75] }, { title: "Length", values: [25, 25.5, 26, 26.5, 27.25, 28, 28.5] }, { title: "Waist", values: [37.5, 39, 40.5, 42, 43.5, 45, 46.5] }],
    'Van Heusen': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'] }, { title: "Chest", values: [34, 35.5, 37.5, 39.5, 41.88, 44.25, 46.63] }, { title: "Shoulder", values: [13.75, 14.5, 15.25, 16, 16.75, 17.5, 18.25] }, { title: "Length", values: [24.5, 25, 25.5, 26, 26.5, 27, 27.5] }, { title: "Waist", values: [31.5, 33, 35, 37, 39.38, 41.75, 44.13] }],
    'Louis Philippe': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL'] }, { title: "Chest", values: [36, 38, 40, 42, 44] }, { title: "Shoulder", values: [14, 14.5, 15, 15.5, 16] }, { title: "Length", values: [23, 23, 24, 25, 25] }],
    'Flying Machine': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL'] }, { title: "Chest", values: [31.5, 33.5, 37.5, 39.5, 41.5, 43.5] }, { title: "Shoulder", values: [15, 16, 17, 18, 19, 20] }, { title: "Length", values: [25, 25.5, 26, 26.5, 27, 27.5] }]
  };
  const shirtSizeCharts = gender === 'Male' ? maleShirtSizeCharts : femaleShirtSizeCharts;

  const malePoloShirtSizeCharts = {
    'U.S.POLO ASSN': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL'] }, { title: "Chest", values: [35, 37, 39, 41, 43, 45, 47, 49] }, { title: "Shoulder", values: [15.75, 16.5, 17.25, 17.75, 18.5, 19.5, 20, 21] }, { title: "Length", values: [25.5, 26.5, 27.5, 28.5, 29, 29.5, 30.5, 31.5] }, { title: "Sleeve", values: [7.25, 7.5, 7.25, 8, 8.25, 8.75, 9.25, 9.75] }],
    'Allen Solly': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: [39, 41, 43, 46, 49, 51] }, { title: "Shoulder", values: [17, 17.5, 18, 18.75, 19.5, 20] }, { title: "Length", values: [26, 27, 28, 29, 30, 31] }],
    'Van Heusen': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: ['32-34', '36-38', '40-42', '44-46', '48-50', '50-52'] }],
    'Louis Philippe': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: [38.19, 40.16, 42.13, 44.09, 47.24, 50.39, 53.54] }, { title: "Shoulder", values: [15.75, 16.54, 17.32, 18.11, 18.9, 19.69, 20.47] }, { title: "Length", values: [25.79, 26.57, 27.36, 28.35, 28.84, 29.63, 30.41] }],
    'Flying Machine': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: [34, 36, 38, 40, 42, 44, 46] }, { title: "Shoulder", values: [13, 14, 15, 16, 17, 18, 19] }, { title: "Length", values: [26, 26.75, 26.75, 27.75, 28.75, 29.75, 30.75] }],
    'Being Human': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: [37.01, 38.98, 40.94, 42.91, 44.88, 46.85] }, { title: "Shoulder", values: [16.14, 16.93, 17.72, 18.5, 19.29, 20.08] }, { title: "Length", values: [26.77, 27.36, 27.95, 28.54, 29.13, 29.72] }]
  };
  const femalePoloShirtSizeCharts = {
    'U.S.POLO ASSN': [{ title: "Size", values: ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '7XL', '8XL', 'Free'] }, { title: "Chest", values: [28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 52] }],
    'Allen Solly': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: [34.5, 36, 38, 40, 42.5, 45, 47] }, { title: "Shoulder", values: [14.38, 14.75, 15.25, 15.75, 16.5, 17.25, 17.75] }, { title: "Length", values: [25, 25.5, 26, 26.5, 27.25, 28, 28.5] }],
    'Van Heusen': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: [32, 33.5, 35, 36.5, 38, 39.5, 41] }, { title: "Shoulder", values: [13, 13.5, 14, 14.5, 15, 15.5, 16] }, { title: "Length", values: [24.5, 25, 25.5, 26, 26.5, 27, 27.5] }],
    // 'Louis Philippe': [{ title: "Size", values: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: [34, 36, 38, 40, 42, 44, 46] }, { title: "Shoulder", values: [14, 14.5, 15, 15.5, 16, 16.5, 17] }, { title: "Length", values: [23, 23.5, 24, 25, 25.5, 26, 26.5] }],
    'Flying Machine': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'] }, { title: "Chest", values: [34, 36, 38, 40, 42, 44] }, { title: "Shoulder", values: [14.5, 15, 15.5, 16, 16.5, 17] }, { title: "Length", values: [24, 24.5, 25, 25.5, 26, 26.5] }],
    'Being Human': [{ title: "Size", values: ['S', 'M', 'L', 'XL', 'XXL', '3XL'] }, { title: "Chest", values: [37.01, 38.98, 40.94, 42.91, 44.88, 46.85] }, { title: "Shoulder", values: [16.14, 16.93, 17.72, 18.5, 19.29, 20.08] }, { title: "Length", values: [26.77, 27.36, 27.95, 28.54, 29.13, 29.72] }]
  };
  const poloShirtSizeCharts = gender === 'Male' ? malePoloShirtSizeCharts : femalePoloShirtSizeCharts;
  
  const maleShoeSizeCharts = {
    'Nike': [{ title: "UK/India", values: [3.5, 4.5, 5.5, 6, 7, 8, 9, 10, 11, 12] }, { title: "Length (in cm)", values: [23, 23.5, 24, 25, 26, 27, 28, 29, 30, 31] }, { title: "Size", values: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13] }, { title: "Euro", values: [36, 37.5, 38.5, 40, 41, 42.5, 44, 45, 46, 47.5] }, { title: "US", values: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13] }],
    'Puma': [{ title: "UK/India", values: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13, 14] }, { title: "Length (in cm)", values: [21.8, 22.3, 22.8, 23.2, 23.7, 24.1, 24.5, 25, 25.4, 25.8, 26.2, 26.7, 27.1, 27.5, 27.9, 28.3, 28.8, 29.2, 29.6, 30.5, 30.8] }, { title: "Size", values: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13, 14] }, { title: "Euro", values: [35.5, 36, 37, 37.5, 38, 38.5, 39, 40, 40.5, 41, 42, 42.5, 43, 44, 44.5, 45, 46, 46.5, 47, 48, 49] }, { title: "US", values: [5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15.5, 16.5] }],
    'Adidas': [{ title: "UK/India", values: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 17, 18, 19] }, { title: "Length (in cm)", values: [21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30, 30.5, 31, 31.5, 32, 32.5, 33, 33.5, 34, 34.5, 35.5, 36.5, 37.5] }, { title: "Size", values: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 17, 18, 19] }, { title: "Euro", values: [35.33, 36, 36.67, 37.33, 38, 38.67, 39.33, 40, 40.67, 41.33, 42, 42.67, 43.33, 44, 44.67, 45.33, 46, 45.67, 47.33, 48, 48.67, 48.33, 50, 50.67, 51.33, 52, 52.67, 53.33, 54.67, 55.67] }, { title: "US", values: [3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17.5, 18.5, 19.5] }],
    'Sketchers': [{ title: "UK/India", values: [6, 7, 8, 9, 10, 11, 12] }, { title: "Length (in cm)", values: [25, 26, 27, 28, 29, 30, 31] }, { title: "Size", values: [7, 8, 9, 10, 11, 12, 13] }, { title: "Euro", values: [39.5, 41, 42, 43, 45, 46, 47.5] }, { title: "US", values: [7, 8, 9, 10, 11, 12, 13] }],
    'Reebok': [{ title: "UK/India", values: [6, 7, 8, 9, 10, 11, 12, 13] }, { title: "Length (in cm)", values: [23.7, 24.7, 25.7, 26.7, 27.7, 28.7, 29.7, 30.7] }, { title: "Size", values: [6, 7, 8, 9, 10, 11, 12, 13] }, { title: "Euro", values: [39, 40.5, 42, 43, 44.5, 45.5, 47, 48.5] }, { title: "US", values: [7, 8, 9, 10, 11, 12, 13, 14] }]
  };
  const femaleShoeSizeCharts = {
    'Nike': [{ title: "UK/India", values: [2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10] }, { title: "Length (in cm)", values: [22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 30] }, { title: "Size", values: [5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13] }, { title: "Euro", values: [35.5, 36, 36.5, 37.5, 38, 38.5, 39, 40, 40.5, 41, 42, 42.5, 43, 44.5, 45, 45.5] }, { title: "US", values: [5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13] }],
    'Puma': [{ title: "UK/India", values: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13, 14] }, { title: "Length (in cm)", values: [21.8, 22.3, 22.8, 23.2, 23.7, 24.1, 24.5, 25, 25.4, 25.8, 26.2, 26.7, 27.1, 27.5, 27.9, 28.3, 28.8, 29.2, 29.6, 30.5, 30.8] }, { title: "Size", values: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13, 14] }, { title: "Euro", values: [35.5, 36, 37, 37.5, 38, 38.5, 39, 40, 40.5, 41, 42, 42.5, 43, 44, 44.5, 45, 46, 46.5, 47, 48, 49] }, { title: "US", values: [5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15.5, 16.5] }],
    'Adidas': [{ title: "UK/India", values: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5] }, { title: "Length (in cm)", values: [21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 26, 30.5, 31] }, { title: "Size", values: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5] }, { title: "Euro", values: [35.33, 36, 36.67, 37.33, 38, 38.67, 39.33, 40, 40.67, 41.33, 42, 42.67, 43.33, 44, 44.67, 45.33, 46, 46.67, 47.33, 48] }, { title: "US", values: [4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14] }],
    'Sketchers': [{ title: "UK/India", values: [2, 3, 4, 5, 6, 7, 8] }, { title: "Length (in cm)", values: [22, 23, 24, 25, 26, 27, 28] }, { title: "Size", values: [5, 6, 7, 8, 9, 10, 11] }, { title: "Euro", values: [35, 36, 37, 38, 39, 40, 41] }, { title: "US", values: [5, 6, 7, 8, 9, 10, 11] }],
    'Reebok': [{ title: "UK/India", values: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8] }, { title: "Length (in cm)", values: [19.2, 19.7, 20.2, 20.7, 21.2, 21.7, 22.2, 22.7, 23.2, 23.7, 24.2, 24.7, 25.2, 25.7, 26.2] }, { title: "Size", values: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8] }, { title: "Euro", values: [33, 34, 34.5, 35, 35.5, 36, 37, 37.5, 38, 38.5, 39, 40, 40.5, 41, 42] }, { title: "US", values: [3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5] }]
  };
  const shoeSizeChart = gender === 'Male' ? maleShoeSizeCharts : femaleShoeSizeCharts;

  const sizeCharts = {
    bottom: { Jeans: pantSizeCharts },
    top: { Shirts: shirtSizeCharts, Tshirts: poloShirtSizeCharts },
    foot: { Shoes:  shoeSizeChart},
  };
  
  const [selectedBrand, setSelectedBrand] = useState('');
  const [clothing, setClothing] = useState('');
  const [category, setCategory] = useState('');
  const categoryRef = useRef(null);
  const selectRef = useRef(null);
  const optionRef = useRef(null)
  const buttonRef = useRef (null)

  useEffect(() => {
    if (category !== '') {
      selectRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [category]);

  useEffect(() => {
    if (selectedBrand !== '') {
      optionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedBrand]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   }, 2500);
  
  //   return () => clearTimeout(timer); // Cleanup timeout on unmount
  // }, []);

  const handleSelectChange = (e) => {
    setSelectedBrand(e.target.value);
  };

  const handleClothingChange = (selected) => {
    setClothing(selected);
    setCategory('')
    setSelectedBrand('');
    categoryRef.current?.scrollIntoView({ behavior: 'smooth' });
    // setSelectedBrand(null);
  };
  
  const renderBrandOptions = () => {
    if (clothing && category && sizeCharts[clothing]?.[category]) {
      return Object.keys(sizeCharts[clothing][category]).map((brand, index) => (
        <option key={index} className="w-fit">{brand}</option>
      ));
    }
  };

  useEffect(() => {
    console.log(`-------------------------------------> ${clothing}`)
  }, [clothing])

  const BrandSizeChart = ({ brand, sizes, selectedSize, type }) => {
    console.log(brand, selectedSize, type)
    // console.log('sizes---------------------------->:  ',sizes)
    let size;

    if (type==='jeans') {
      size = getPantSize(brand, pantSizes);
    } else if (type==='shirts') {
      size = getShirtSize(brand, shirtSizes);
    } else if (type==='tshirts') {
      size = getTShirtSize(brand, tShirtSizes);
    } else if (type==='foot') {
      size = getFootSize(brand)
    }

    console.log('size---------------------->', size)
    const getSizeIndex = (chart, size) => {
      const sizeSection = chart.find(section => section.title.includes('Size') || section.title.includes('Size'));
      if (!sizeSection) {
        console.log('NO selection -----------------------------!!!!!!!')
        return -1;
      }

      
      console.log(sizeSection.values.findIndex(value => value === size))
      return sizeSection.values.findIndex(value => value === size);
    };

    const getSizeData = (chart, index) => {
      if (index === -1) return null;

      return chart.reduce((data, section) => {
        data[section.title] = section.values[index];
        return data;
      }, {});
    };

    const sizeIndex = getSizeIndex(sizes, size);
    const sizeData = getSizeData(sizes, sizeIndex);

    return (
      <>
        <div ref={optionRef} className="w-[95%] tab:w-[55rem] mb-3 text-tbg1 text-[0.92em] text-center italic font-medium opacity-80">
          *NOTE: THE RECOMMENDED MEASUREMENT MAY VARY BASED ON YOUR BODY SHAPES
        </div>
        {size === 'Size not available' && (
          <div className="mb-3 text-red-400 text-xl font-semibold">Size not available</div>
        )}
        <div className="w-[95%] tab:w-[55rem] h-fit flex flex-row border-l-2 border-b-2 border-cbg4">
          {sizes.map((section, index) => (
            <div key={index} className="w-full h-fit flex flex-col justify-center items-center border-r-2 border-cbg4">
              <div className="w-full h-12 p-0.5 tab:p-2 flex justify-center items-center border-y-2 border-cbg4">
                <div className="w-full h-full flex justify-center items-center rounded-md bg-tbg2 text-white">
                  {section.title}
                </div>
              </div>
              {section.values.map((value, idx) => {
                const isSelected = idx === sizeIndex;
                return (
                  <div
                    key={idx}
                    className={`w-full h-12 p-2 flex justify-center items-center border-b-0 border-cbg4`}
                  >
                    <div
                      className={`w-full h-full flex justify-center items-center ${isSelected ? 'bg-cbg4 shadow-custom-1' : 'bg-white shadow-custom-1'}`}
                    >
                      {value}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </>
    );
  };
  
  return (
    <div>
      {/* D E S K T O P -------------------------------------------------------------------------> */}
      <div className={styles.container}>
        <div className={styles.firstsection}>
          <div ref={buttonRef} className={`${styles.heading} hidden`}>Measurements</div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Weight</th>
                <th>Height</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{name}</td>
                <td>{age} yrs</td>
                <td>{gender}</td>
                <td>{weight} kg</td>
                <td>{height} m</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.insidecontainer}>
          <div className={styles.rescon}>
            <table className={styles.table1}>
              <thead>
                <tr>
                  <th>Body Points</th>
                  <th>Measurement</th>
                  <th style={{ position: "static" }}>
                    Units
                    <button
                      onClick={toggleUnit}
                      className={styles.toggleButton}
                    >
                      {unit === "cm" ? "Switch to Inch" : "Switch to cm"}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Neck</td>
                  <td>{formatMeasurement(result?.Neck)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Shoulder</td>
                  <td>{formatMeasurement(result?.Shoulder)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Arm Length</td>
                  <td>{formatMeasurement(result?.Arm)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Chest/Bust</td>
                  <td>{formatMeasurement(result?.Chest)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Waist</td>
                  <td>{formatMeasurement(result?.Waist)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Hip</td>
                  <td>{formatMeasurement(result?.Hip)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Thigh</td>
                  <td>{formatMeasurement(result?.Thigh)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Knee</td>
                  <td>{formatMeasurement(result?.Knee)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Ankle</td>
                  <td>{formatMeasurement(result?.Ankle)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Inseam</td>
                  <td>{formatMeasurement(result?.Inseam)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Outseam</td>
                  <td>{formatMeasurement(result?.Outseam)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Foot Length</td>
                  {/* <td>{formatMeasurement((result?.Foot)*2/3)}</td> */}
                  <td>{formatMeasurement(((result?.Foot)*0.667)-1)}</td>
                  <td>{unit}</td>
                </tr>
              </tbody>
            </table>
            {/* <div className={styles.Button}>
            </div> */}
          </div>
          <div className={styles.modelimg}>
            <img src={bodyModel} alt="Body Model" className={styles.image} />

            {/* <img src={neck} alt="Overlay" className={styles.neck} /> */}
            {/* <img src={chest} alt="Overlay" className={styles.chest} />
                        <img src={waist} alt="Overlay" className={styles.waist} />
                        <img src={hip} alt="Overlay" className={styles.hip} /> */}

            {/* <img src={shoulder} alt="Overlay" className={styles.shoulder} /> */}
          </div>
        </div>
      </div>

      {/* M O B I L E -------------------------------------------------------------------------> */}
      <div className={styles.mobcontainer}>
        <div className={styles.heading}>Measurement</div>
        <div className={styles.con1}>
          <div className={styles.inputBox1}>
            <label>Name</label>
            <div className={styles.inputField1}>{name}</div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputBox}>
              <label>Age</label>
              <div className={styles.inputField}>{age} yrs</div>
            </div>
            <div className={styles.inputBox}>
              <label>Gender</label>
              <div className={styles.inputField}>{gender}</div>
            </div>
            <div className={styles.inputBox}>
              <label>Height</label>
              <div className={styles.inputField}>{height} m</div>
            </div>
            <div className={styles.inputBox}>
              <label>Weight</label>
              <div className={styles.inputField}>{weight} kg</div>
            </div>
          </div>
        </div>
        <div className={styles.modelimg}>
          <img src={bodymob} alt="Body Model" className={styles.image} />
        </div>
        <div className={styles.rescon}>
          <table className={styles.table1}>
            <thead>
              <tr>
                <th>Body Points</th>
                <th>Measurement</th>
                <th>
                  Units
                  <button onClick={toggleUnit} className={styles.toggleButton}>
                    {unit === "cm" ? "Switch to Inch" : "Switch to cm"}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Neck</td>
                <td>{formatMeasurement(result?.Neck)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Shoulder</td>
                <td>{formatMeasurement(result?.Shoulder)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Arm Length</td>
                <td>{formatMeasurement(result?.Arm)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Chest/Bust</td>
                <td>{formatMeasurement(result?.Chest)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Waist</td>
                <td>{formatMeasurement(result?.Waist)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Hip</td>
                <td>{formatMeasurement(result?.Hip)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Thigh</td>
                <td>{formatMeasurement(result?.Thigh)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Knee</td>
                <td>{formatMeasurement(result?.Knee)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Ankle</td>
                <td>{formatMeasurement(result?.Ankle)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Inseam</td>
                <td>{formatMeasurement(result?.Inseam)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Outseam</td>
                <td>{formatMeasurement(result?.Outseam)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Shoe Size</td>
                {/* <td>{formatMeasurement((result?.Foot)*2/3)}</td> */}
                <td>{formatMeasurement(((result?.Foot)*0.667)-1)}</td>
                <td>{unit}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <PDFDownloadLink
          document={
            <MyPdf
              dataset={result}
              name={name}
              age={age}
              gender={gender}
              height={height}
              weight={weight}
              // foot={(result?.Foot - 0.5)*2/3}
            />
          }
          fileName={`${name}_measurements.pdf`}
          className={`${styles.downloadButton} flex tab:hidden w-28 h-12 tab:h-fit mb-2 py-2 mx-2 text-center justify-center items-center transition duration-300 ease-in-out hover:scale-95 `}
        >
          Download
        </PDFDownloadLink>{" "}
      </div>

      {/* M O D I F I E D ------------------------------------------------------------> */}
      <div className="relative flex flex-col justify-start items-center py-0 tab:py-0 tab:my-8 xl:my-8 xl:mb-10 bg-cbg1 tab:bg-transparent text-tbg2 text-base">
        <div className="select-none flex flex-row pb-4 tab:pb-5 text-center">
          <PDFDownloadLink
            document={
              <MyPdf
                dataset={result}
                name={name}
                age={age}
                gender={gender}
                height={height}
                weight={weight}
                // foot={(result?.Foot - 0.5)*2/3}
              />
            }
            fileName={`${name}_measurements.pdf`}
            className={`${styles.downloadButton} flex xxs:hidden tab:flex w-28 h-12 tab:h-fit tab:w-36 py-2 mx-4 text-center justify-center items-center transition duration-300 ease-in-out hover:scale-95 `}
          >
            Download
          </PDFDownloadLink>
          <button className={`w-28 tab:w-36 h-12 tab:h-fit py-2 mx-2 tab:mx-4 rounded-xl tab:rounded-lg shadow-custom-2 ${clothing === 'top' ? 'text-white bg-tbg2' : 'hover:text-white bg-cbg4 hover:bg-tbg2'} cursor-pointer transition duration-300 ease-in-out tab:hover:scale-95`} onClick={() => handleClothingChange('top')}>Top wear</button>
          <button className={`w-28 tab:w-36 h-12 tab:h-fit py-2 mx-2 tab:mx-4 rounded-xl tab:rounded-lg shadow-custom-2 ${clothing === 'bottom' ? 'text-white bg-tbg2' : 'hover:text-white bg-cbg4 hover:bg-tbg2'} cursor-pointer transition duration-300 ease-in-out tab:hover:scale-95`} onClick={() => handleClothingChange('bottom')}>Bottom wear</button>
          <button className={`w-28 tab:w-36 h-12 tab:h-fit py-2 mx-2 tab:mx-4 rounded-xl tab:rounded-lg shadow-custom-2 ${clothing === 'foot' ? 'text-white bg-tbg2' : 'hover:text-white bg-cbg4 hover:bg-tbg2'} cursor-pointer transition duration-300 ease-in-out tab:hover:scale-95`} onClick={() => handleClothingChange('foot')}>Foot wear</button>
        </div>
        <div ref={categoryRef} className="select-none flex flex-row pb-5 tab:pb-5 text-center">
          {clothing==='top' && (
            <button 
              className={`w-28 tab:w-36 h-12 tab:h-fit py-2 mx-2 tab:mx-4 rounded-xl tab:rounded-lg shadow-custom-2 ${category === 'Shirts' ? 'text-white bg-tbg2' : 'hover:text-white bg-cbg4 hover:bg-tbg2'} cursor-pointer transition duration-300 ease-in-out tab:hover:scale-95`} 
              onClick={() => {setCategory('Shirts'); setSelectedBrand('');}}>
                Shirts
              </button>)}
          {clothing==='top' && (
            <button 
              className={`w-28 tab:w-36 h-12 tab:h-fit py-2 mx-2 tab:mx-4 rounded-xl tab:rounded-lg shadow-custom-2 ${category === 'Tshirts' ? 'text-white bg-tbg2' : 'hover:text-white bg-cbg4 hover:bg-tbg2'} cursor-pointer transition duration-300 ease-in-out tab:hover:scale-95`} 
              onClick={() => {setCategory('Tshirts'); setSelectedBrand('');}}>
                Tshirts
              </button>)}
          {clothing==='bottom' && (
            <button 
              className={`w-28 tab:w-36 h-12 tab:h-fit py-2 mx-2 tab:mx-4 rounded-xl tab:rounded-lg shadow-custom-2 ${category === 'Jeans' ? 'text-white bg-tbg2' : 'hover:text-white bg-cbg4 hover:bg-tbg2'} cursor-pointer transition duration-300 ease-in-out tab:hover:scale-95`} 
              onClick={() => {setCategory('Jeans'); setSelectedBrand('');}}>
                Jeans
              </button>)}
          {clothing==='foot' && (
            <button 
              className={`w-28 tab:w-36 h-12 tab:h-fit py-2 mx-2 tab:mx-4 rounded-xl tab:rounded-lg shadow-custom-2 ${category === 'Shoes' ? 'text-white bg-tbg2' : 'hover:text-white bg-cbg4 hover:bg-tbg2'} cursor-pointer transition duration-300 ease-in-out tab:hover:scale-95`} 
              onClick={() => {setCategory('Shoes'); setSelectedBrand('');}}>
                Shoes
              </button>)}
        </div>
        {category!=='' && (
          <div ref={selectRef} className="relative flex flex-row mb-5 text-center">
            <select
              id="brandSelect"
              className="w-[20rem] h-14 tab:h-fit py-2 pl-2 pr-8 rounded-lg bg-white border-2 border-cbg4 hover:border-tbg2 cursor-pointer appearance-none transition duration-500 ease-in-out focus:outline-none"
              onChange={handleSelectChange}
              value={selectedBrand}
            >
              <option className="w-fit" value="" selected disabled>Select a brand</option>
              {renderBrandOptions()}
            </select>
            <svg className="h-12 w-12 text-tbg2 absolute top-1/2 right-[-3%] -translate-y-1/2 px-4 py-3 pointer-events-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.9mb-8 tab:mb-038a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
            </svg>
          </div>
        )}
        {clothing !== '' && category !== '' && selectedBrand && (
          <BrandSizeChart
            brand={selectedBrand}
            sizes={
              clothing === 'bottom'
                ? sizeCharts.bottom[category][selectedBrand]
                : clothing === 'top'
                ? sizeCharts.top[category][selectedBrand]
                : sizeCharts.foot[category][selectedBrand]
            }
            selectedSize={
              clothing === 'bottom'
                ? pantSizes
                : clothing === 'top' && category === 'Shirts'
                ? shirtSizes
                : category === 'Tshirts'
                ? tShirtSizes
                : clothing === 'foot' && category === 'Shoes'
                ? footSize
                : null
            }
            type={
              clothing === 'bottom'
                ? 'jeans'
                : clothing === 'top' && category === 'Shirts'
                ? 'shirts'
                : category === 'Tshirts'
                ? 'tshirts'
                : clothing === 'foot' && category === 'Shoes'
                ? 'foot'
                : null
            }            
          />
      )}
      </div>
    </div>
  );
};

export default Result;